/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.claim-select {
  width: 150px;
}
.claim-table::v-deep {
  min-width: 1450px;
}
.claim-table::v-deep th {
  text-align: center;
}
.claim-table::v-deep .col-company {
  width: 120px;
}
.claim-table::v-deep .col-service-no {
  width: 150px;
}
.claim-table::v-deep .col-price {
  width: 100px;
}
.claim-table::v-deep .col-date {
  width: 125px;
}
.claim-table::v-deep .col-status {
  width: 125px;
}
.mobile .claim-table::v-deep .ant-table,
.tablet .claim-table::v-deep .ant-table {
  font-size: 0.9em;
}
.mobile .claim-table::v-deep .ant-table .col-company,
.tablet .claim-table::v-deep .ant-table .col-company {
  width: 100px;
}
.mobile .claim-table::v-deep .ant-table .col-service-no,
.tablet .claim-table::v-deep .ant-table .col-service-no {
  width: 135px;
}
.mobile .claim-table::v-deep .ant-table .col-price,
.tablet .claim-table::v-deep .ant-table .col-price {
  width: 80px;
}
.claim-table::v-deep .ant-table-tbody .col-status,
.claim-table::v-deep .ant-table-tbody .col-date {
  text-align: center;
  font-size: 0.95em;
}
.claim-table::v-deep .ant-table-tbody .col-price {
  text-align: right;
  font-size: 0.95em;
}
